<template>
  <div class="container">
    <div class="pucontainer">
      <div class="bredcrumbBox" v-show="info">
        <span class="iconfont icon-jtback" @click="$router.go(-1)"></span>
        <span @click="goHome">{{
          info.sexguid == 1
          ? $t("common.men")
          : info.sexguid == 2
            ? $t("common.women")
            : $t("common.kids")
        }}</span>
        <span v-if="info.isDiscount" @click="goGoodsList('全部折扣')">&nbsp;/ {{ $t("common.sale") }}</span>
        <span @click="goGoodsList()">&nbsp;/
          {{
            info.sexguid == 3
            ? $t("common.boy")
            : info.sexguid == 4
              ? $t("common.girl")
              : $t("common.collections")
          }}</span>
        <span @click="goGoodsList('分类')">&nbsp;/
          {{
            $i18n.locale == "en"
            ? info.parentcategorynameen
            : info.parentcategoryname
          }}</span>
        <span @click="goGoodsList('子分类')">&nbsp;/
          {{
            $i18n.locale == "en" ? info.categorynameen : info.categoryname
          }}</span>
      </div>
      <div class="goodsBox">
        <div class="g_left">
          <div class="allimg">
            <swiper @swiper="onSwiper" direction="vertical" :slides-per-view="3" style="height: 600px">
              <swiper-slide v-for="(item, i) in info.swiper" :key="i">
                <div class="reitem" :class="i == imgIndex ? 'active' : ''" @click="imgIndex = i">
                  <q-img :src="item" alt :ratio="6 / 8" />
                </div>
              </swiper-slide>
            </swiper>
            <div class="prev" v-if="imgIndex != 0" @click="prev()">
              <span class="iconfont icon-swiperLeft"></span>
            </div>
            <div class="next" v-if="info && imgIndex != info.swiper.length - 1" @click="next()">
              <span class="iconfont icon-swiperRight"></span>
            </div>
          </div>
          <div class="nowimg-box">
            <div class="nowimg" @click="loupeHandle(info)">
              <q-img v-if="info && info.swiper.length" :src="info.swiper[imgIndex]" alt :ratio="6 / 8" />
            </div>
            <div class="left-nav" v-if="imgIndex != 0" @click="prev()">
              <span class="iconfont icon-swiperLeft"></span>
            </div>
            <div class="right-nav" v-if="info && imgIndex != info.swiper.length - 1" @click="next()">
              <span class="iconfont icon-swiperRight"></span>
            </div>
          </div>
        </div>
        <div class="g_right">
          <h5 @click="goGoodsList('品牌')">{{ info.generalattributename }}</h5>
          <p>{{ $i18n.locale == "en" ? info.titleen : info.title }}</p>
          <p class="price">
            <span class="line-through" v-if="size.isDiscount == 1">￥{{ size.oPrice }}</span>
            <span v-if="info.isDiscount">{{
              disPercentage(size.oPrice, size.Price)
            }}</span>
            ￥{{ size.Price }}
          </p>
          <div class="integral">
            {{ $t("goods.get") }}&nbsp;{{ size.Price }}&nbsp;{{ $t("goods.points") }}
          </div>
          <div class="choose">
            <div class="selectdiv">
              <q-select v-model="colors" :option-label="$i18n.locale == 'en' ? 'colorsen' : 'colors'"
                :options="colorOptions" :label="$t('goods.color')" @update:model-value="colorChange(colors)" />
            </div>
            <div class="selectdiv" v-show="size.size != '01'">
              <q-select v-model="size" :options="sizeOptions" option-label="size" :label="$t('goods.size')" />
            </div>
          </div>
          <div class="btnBox">
            <div class="btn">
              <q-btn color="primary" class="full-width" :label="$t('goods.add_cart')" @click="addCart"
                :loading="cartLoading" />
            </div>
            <div class="btn-top">
              <div class="btn">
                <q-btn outline class="full-width" :label="$t('goods.add_wish')"
                  :icon-right="info.iswish ? 'iconfont icon-like_fill' : 'iconfont icon-like'" @click="addWish(info)"
                  :loading="wishLoading" />
              </div>
              <div class="btn" v-if="userInfo.usertype == 1" style="margin-left: 15px">
                <q-btn id="copy" outline :data-clipboard-text="saleUrl" color="primary" class="full-width"
                  :label="$t('goods.copy_link')" @click="getSaleUrl" />
              </div>
            </div>
          </div>
          <!-- 产品详情 -->
          <div class="collsep">
            <q-list>
              <q-expansion-item switch-toggle-side expand-separator :label="$t('goods.product_detail')" default-opened
                :header-style="'border-bottom: 1px solid #ccc'">
                <q-card>
                  <q-card-section>
                    <div class="conlist">
                      <div class="spotitem">
                        <span>{{ $t("goods.code") }}{{ info.code }}</span>
                        <span class="copy-button" id="goodsDetail" :data-clipboard-text="clipboardTextDetail"
                          @click="copyGoodsDetail">{{ $t('vip.copy') }}</span>
                      </div>
                      <template v-if="$i18n.locale == 'en'">
                        <div class="spotitem" v-for="(item, index) in info.detailen" :key="index" v-html="item"></div>
                      </template>
                      <template v-else>
                        <div class="spotitem" v-for="(item, index) in info.detail" :key="index" v-html="item"></div>
                      </template>
                    </div>
                  </q-card-section>
                </q-card>
              </q-expansion-item>
              <q-expansion-item switch-toggle-side expand-separator default-opened :label="$t('goods.share_to')"
                :header-style="'border-bottom: 1px solid #ccc'">
                <q-card>
                  <q-card-section>
                    <div class="share-img">
                      <div class="share-list" v-for="(item, index) in options" :key="index">
                        <img :src="item.icon" @click="dealShare(item.code)" />
                      </div>
                    </div>
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </q-list>
          </div>
        </div>
      </div>
    </div>
    <div class="recommon" v-if="info.withcommodity && info.withcommodity.length">
      <div class="putitle">
        <span>{{ $t("goods.recommend_goods") }}</span>
      </div>
      <div class="recommend_swiper">
        <swiper navigation :slides-per-group="5" :slides-per-view="5" @slideChangeTransitionEnd="cateEnd">
          <swiper-slide v-for="(item, index) in info.withcommodity" :key="index">
            <div class="reitem">
              <div class="top" @mouseover="item.imgShow = true" @mouseleave="item.imgShow = false">
                <router-link :to="'/goods?guid=' + item.guid + '&discount=' + item.isDiscount
                  ">
                  <q-img :ratio="6 / 8" class="cursor-pointer" :src="item.imgShow
                    ? item.commoditypictures1.split(',')[1]
                    : item.commoditypictures1.split(',')[0]
                    " />
                </router-link>
                <span @click.stop="addWish(item)" v-if="!item.iswish" class="like iconfont icon-like"></span>
                <span @click.stop="addWish(item)" v-else class="like iconfont icon-like_fill"></span>
                <span class="loupe iconfont icon-sousuo" @click.stop="loupeHandle(item)"></span>
              </div>
              <div class="retxt">
                <p class="blod">{{ item.generalattributename }}</p>
                <p>{{ $i18n.locale == "en" ? item.titleen : item.title }}</p>
                <p class="blod line-through" v-if="item.isDiscount == 1">
                  ￥{{ item.oPrice }}
                </p>
                <p class="blod">￥{{ item.Price }}</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="recommon" v-if="info.categorycommodity && info.categorycommodity.length">
      <div class="putitle">
        <span>{{ $t("goods.recommend_brands") }}</span>
      </div>
      <div class="recommend_swiper">
        <swiper navigation :slides-per-group="5" :slides-per-view="5" @slideChangeTransitionEnd="brandEnd">
          <swiper-slide v-for="(item, index) in info.categorycommodity" :key="index">
            <div class="reitem">
              <div class="top" @mouseover="item.imgShow = true" @mouseleave="item.imgShow = false">
                <router-link :to="'/goods?guid=' + item.guid + '&discount=' + item.isDiscount
                  ">
                  <q-img :ratio="6 / 8" class="cursor-pointer" :src="item.imgShow
                    ? item.commoditypictures1.split(',')[1]
                    : item.commoditypictures1.split(',')[0]
                    " />
                </router-link>
                <span @click.stop="addWish(item)" v-if="!item.iswish" class="like iconfont icon-like"></span>
                <span @click.stop="addWish(item)" v-else class="like iconfont icon-like_fill"></span>
                <span class="loupe iconfont icon-sousuo" @click.stop="loupeHandle(item)"></span>
              </div>
              <div class="retxt">
                <p class="blod">{{ item.generalattributename }}</p>
                <p>{{ $i18n.locale == "en" ? item.titleen : item.title }}</p>
                <p class="blod line-through" v-if="item.isDiscount == 1">
                  ￥{{ item.oPrice }}
                </p>
                <p class="blod">￥{{ item.Price }}</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <q-dialog v-model="cartShow">
      <q-card style="width: 450px">
        <q-card-section>
          <div class="cart-show">
            <div class="title">{{ $t("goods.add_cart_suss") }}</div>
            <div class="content">
              <div class="content-list">
                <div class="label">{{ $t("goods.brand") }}</div>
                <div class="cont">{{ info.generalattributename }}</div>
              </div>
              <div class="content-list">
                <div class="label">{{ $t("goods.goods") }}</div>
                <div class="cont">
                  {{ $i18n.locale == "en" ? info.titleen : info.title }}
                </div>
              </div>
              <div class="content-list">
                <div class="label">{{ $t("goods.price") }}</div>
                <div class="cont">
                  <span class="line-through" v-if="size.isDiscount == 1">￥{{ size.oPrice }}</span>
                  ￥{{ size.Price }}
                </div>
              </div>
              <div class="content-list">
                <div class="label">{{ $t("goods.color") }}</div>
                <div class="cont">
                  {{ $i18n.locale == "en" ? size.colorsen : size.colors }}
                </div>
              </div>
              <div class="content-list" v-if="size.size != '01'">
                <div class="label">{{ $t("goods.size") }}</div>
                <div class="cont">{{ size.size }}</div>
              </div>
            </div>
            <div class="button-box">
              <q-btn class="full-width" color="white" text-color="black" :label="$t('goods.go_shopping')"
                @click="cartShow = false" />
              <div style="width: 20px"></div>
              <q-btn class="full-width" color="primary" :label="$t('goods.go_pay')" @click="$router.push('/cart')" />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { copyHandle } from '@/utils/common'
import { swiperConfig } from '@/plugins/swiperConfig'
import { getgoods, cartApi, goodslist } from '@/api/goods'
import { statistics } from '@/api/common'
import { wish } from '@/api/my'
import _ from 'lodash'
import store from '../../store'
const { Swiper, SwiperSlide } = swiperConfig()

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      imgIndex: 0,
      info: '', // 商品信息
      colorOptions: [], // 颜色选项
      sizeOptions: [], // 尺寸选项
      colors: '',
      size: '',
      cartLoading: false,
      wishLoading: false,
      saleUrl: '', // 销售链接
      cartShow: false,
      swiper: '',
      options: [
        {
          name: this.$t('goods.wechat'),
          icon: 'https://img.yzcdn.cn/vant/share-sheet-wechat.png',
          code: 'weixin'
        },
        {
          name: 'Twitter',
          icon: 'https://annilux.com/img/Twitter.png',
          code: 'twitter'
        },
        {
          name: 'LinkedIn',
          icon: 'https://annilux.com/img/social-linkedin.png',
          code: 'linkedin'
        },
        {
          name: 'Facebook',
          icon: 'https://annilux.com/img/Facebook.png',
          code: 'facebook'
        }
      ],
      catePageindex: 1,
      brandPageindex: 1,
      cateSwiperFinish: false,
      brandSwiperFinish: false,
      clipboardTextDetail: ''
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    pageType() {
      return this.$store.state.pageType
    },
    disPercentage() {
      return function (oPrice, Price) {
        const num = (_.round(1 - Price / oPrice, 2) * 100).toFixed(1)
        return ` -${num}% `
      }
    }
  },
  watch: {
    $route(to) {
      if (to.name === 'Goods') {
        statistics({
          guids: this.info.guids
        })
        this.getGood()
      }
    }
  },
  methods: {
    copyGoodsDetail() {
      let text = '';
      if (this.$i18n.locale === 'en') {
        text = this.info.detailen.join('\n')
      } else {
        text = this.info.detail.join('\n')
      }
      this.clipboardTextDetail = `${this.$t('goods.code')}${this.info.code}\n${text}`
      copyHandle('#goodsDetail')
    },
    // 获取商品
    async getGood() {
      const res = await getgoods({
        commodityguid: this.$route.query.guid,
        type: this.pageType.id,
        channel: this.$i18n.locale === 'en' ? 2 : 1,
        terminal: 3
      })
      if (res.status === 1) {
        this.info = res.commodity[0]
        this.info.swiper = res.commodity[0].commoditypictures1.split(',')
        try {
          this.info.detail = decodeURIComponent(this.info.commoditydetails).split('. ')
          this.info.detailen = decodeURIComponent(this.info.commoditydetailsen).split('. ')
        } catch (e) { }
        if (
          res.commodity[0].salesspecifications &&
          res.commodity[0].salesspecifications.length
        ) {
          // 获取颜色选项
          this.colorOptions = res.commodity[0].salesspecifications
          // 获取尺寸选项
          this.sizeOptions =
            res.commodity[0].salesspecifications[0].salesspecifications
          this.colors = res.commodity[0].salesspecifications[0]
          this.size =
            res.commodity[0].salesspecifications[0].salesspecifications[0]
        }
        this.isPageDiff()
        this.addCateSlider()
        this.addBrandSlider()
        if (this.info.isDiscount) {
          this.$router.replace({
            path: '/goods',
            query: {
              guid: this.info.guid,
              discount: 1,
              userguid: this.$route.query.userguid
            }
          })
        }
      }
    },
    // 加入购物车
    async addCart() {
      if (this.size.number === 0) {
        this.$q.notify(this.$t('goods.no_stock'))
        return
      }
      this.cartLoading = true
      const res = await cartApi({
        type: 1,
        mark: this.info.guid,
        sguid: this.size.guid,
        quantity: 1,
        userguid: this.$store.state.saleGuid || ''
      })
      if (res.status === 1) {
        this.cartShow = true
      } else {
        this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
      }
      this.cartLoading = false
    },
    // 添加收藏
    async addWish(item) {
      this.wishLoading = true
      const res = await wish({
        type: 1,
        mark: item.guid
      })
      if (res.status === 1) {
        item.iswish = true
      } else {
        item.iswish = false
      }
      this.wishLoading = false
    },
    colorChange(item) {
      // this.sizeOptions = item.salesspecifications
      // this.size = item.salesspecifications[0]
      this.$router.replace({
        path: '/goods',
        query: {
          guid: item.guid,
          discount: this.$route.query.discount
        }
      })
    },
    // 获取销售链接
    getSaleUrl() {
      const url = `${window.location.origin}${window.location.pathname}?guid=${this.info.guid}&userguid=${this.userInfo.userguid}`
      this.saleUrl = url
      copyHandle('#copy')
    },
    // 跳转到商品列表页
    goGoodsList(type) {
      const params = _.cloneDeep(store.state.goodsModule.originalParams)
      params.htype = this.info.sexguid
      if (type === '分类') {
        params.parentcategoryid = this.info.parentcategoryid
      } else if (type === '子分类') {
        params.parentcategoryid = this.info.parentcategoryid
        params.categoryid = this.info.categoryid
      } else if (type === '品牌') {
        store.commit('setOnlyBrand', true)
        params.generalattributeid = JSON.stringify([
          { 品牌: [this.info.generalattributeguid] }
        ])
      } else if (type === '全部折扣') {
        params.htype = ''
      }
      this.$router.push({
        path: '/goodslist',
        query: {
          params: encodeURI(JSON.stringify(params)),
          ...(this.info.isDiscount && { discount: this.info.isDiscount })
        }
      })
      this.isPageDiff()
    },
    goHome() {
      this.$router.push('/')
      this.isPageDiff()
    },
    // 如果当前商品类型不和页面类型相同，则改变PageType
    isPageDiff() {
      if (this.info.sexguid !== this.$store.state.pageType.id) {
        const obj = {
          id: this.info.sexguid,
          title: this.info.sexname
        }
        setTimeout(() => {
          this.$store.commit('setPageType', obj)
        }, 100)
      }
    },
    prev() {
      if (this.imgIndex === 0) {
        return
      }
      this.swiper.slidePrev()
      this.imgIndex--
    },
    next() {
      this.swiper.slideNext()
      if (this.imgIndex === this.info.swiper.length - 1) {
        return
      }
      this.imgIndex++
    },
    onSwiper(swiper) {
      this.swiper = swiper
    },
    // 图片预览
    loupeHandle(item) {
      this.$viewerApi({
        options: {
          transition: false
        },
        images: item.commoditypictures1.split(',')
      })
    },
    // 分享
    dealShare(code) {
      /* eslint-disable */
      if (process.env.NODE_ENV === 'production') {
        bShare.addEntry({
          title: '',
          url: location.href,
          summary: '',
          pic: ''
        })
        bShare.share('', code)
      }
      /* eslint-enable */
    },
    // 推荐商品最后一页
    cateEnd(swiper, event) {
      if (swiper.isEnd) {
        this.addCateSlider()
      }
    },
    // 推荐品牌最后一页
    brandEnd(swiper, event) {
      if (swiper.isEnd) {
        this.addBrandSlider()
      }
    },
    async addCateSlider() {
      if (this.cateSwiperFinish) {
        return
      }
      const res = await getgoods({
        commodityguid: this.info.guid,
        htype: this.info.sexguid, // 男女类别
        typeid: 1,
        pageindex: this.catePageindex,
        pagesize: 5,
        generalattributeid: JSON.stringify([
          { 品牌: [this.info.generalattributeguid] }
        ]),
        isdiscount: this.info.isDiscount,
        columns: 1
      })
      if (res.status === 1) {
        if (res.commodity[0].withcommodity.length < 5) {
          this.cateSwiperFinish = true
        }
        this.info.withcommodity.push(...res.commodity[0].withcommodity)
        this.catePageindex++
      }
    },
    async addBrandSlider() {
      if (this.brandSwiperFinish) {
        return
      }
      const res = await getgoods({
        commodityguid: this.info.guid,
        htype: this.info.sexguid, // 男女类别
        typeid: 1,
        pageindex: this.brandPageindex,
        pagesize: 5,
        parentcategoryid: this.info.parentcategoryid,
        isdiscount: this.info.isDiscount,
        columns: 2
      })
      if (res.status === 1) {
        if (res.commodity[0].categorycommodity.length < 5) {
          this.brandSwiperFinish = true
        }
        this.info.categorycommodity.push(...res.commodity[0].categorycommodity)
        this.brandPageindex++
      }
    }
  },
  mounted() {
    // 有销售链接则存store
    if (this.$route.query.userguid) {
      this.$store.commit('setSaleGuid', this.$route.query.userguid)
    }
    this.getGood()
  },
  beforeUnmount() {
    statistics({
      guids: this.info.guids
    })
  }
}
</script>
<style lang="scss" scoped>
.cart-show {
  .title {
    margin-bottom: 15px;
    font-size: 16px;
  }

  .content {
    margin-bottom: 30px;

    .content-list {
      display: flex;
      margin-bottom: 10px;

      .label {
        width: 30%;
        font-weight: bold;
      }

      .cont {
        width: 70%;
      }
    }
  }

  .button-box {
    display: flex;
    justify-content: space-between;
  }
}

.bredcrumbBox {
  max-width: 1500px;
  margin: 10px auto;
  padding: 0 10px;
  text-transform: uppercase;

  .iconfont {
    font-size: 14px;
    margin-right: 10px;
  }

  span {
    cursor: pointer;
  }
}

.goodsBox {
  max-width: 1500px;
  display: flex;
  padding: 0 10px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 30px auto;

  .g_left {
    display: flex;
    width: 60%;
    flex-shrink: 0;

    .allimg {
      position: relative;
      width: 155px;
      min-width: 155px;
      overflow: hidden;

      .reitem {
        cursor: pointer;
        border: 1px solid #a9a9a9;
        margin: 0 3px;
        opacity: 0.5;

        &.active {
          opacity: 1;
        }
      }

      .prev,
      .next {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
        cursor: pointer;

        span {
          font-size: 24px;
        }
      }

      .prev {
        top: 1px;
      }

      .next {
        bottom: 3px;
      }
    }

    .nowimg-box {
      width: 100%;
      position: relative;
      margin-right: 15px;
      margin-left: 15px;

      .nowimg {
        max-width: 450px;
        overflow: hidden;
        margin: 0 auto;
        cursor: pointer;
      }

      .left-nav,
      .right-nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        span {
          font-size: 34px;
          cursor: pointer;
        }
      }

      .left-nav {
        left: 10px;
      }

      .right-nav {
        right: 10px;
      }
    }
  }

  .g_right {
    width: 40%;

    h5 {
      font-size: 20px;
      font-weight: bold;
      margin: 0;
      cursor: pointer;
    }

    p {
      font-size: 18px;
      margin-bottom: 0px;
    }

    .price {
      font-weight: bold;
    }

    .integral {
      display: inline-block;
      padding: 5px 20px;
      font-size: 12px;
      color: #fff;
      border-radius: 10px;
      background-color: #000;
    }

    .choose {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      .selectdiv {
        flex: 1;

        &:last-child {
          margin-left: 10px;
        }
      }
    }

    .btnBox {
      margin: 30px auto;

      .btn-top {
        display: flex;
        margin-top: 15px;

        .btn {
          width: 100%;
        }
      }
    }

    .collsep {
      .conlist {
        display: flex;
        flex-direction: column;

        .spotitem {
          padding-left: 10px;
          display: flex;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
          font-size: 14px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          &::before {
            content: "";
            display: inline-block;
            width: 5px;
            min-width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #000;
            margin-right: 5px;
            margin-top: 6px;
          }
        }

        .copy-button {
          text-decoration: underline;
          margin-left: 10px;
          cursor: pointer;
        }
      }

      .share-img {
        display: flex;

        .share-list {
          cursor: pointer;
          margin-right: 10px;

          img {
            width: 50px;
          }
        }
      }
    }
  }
}

.recommend_swiper {
  max-width: 1500px;
  margin: 0 auto;
  --swiper-theme-color: #000;
}

.recommon {
  margin-top: 50px;

  .reitem {
    .top {
      width: 100%;
      position: relative;
      padding: 10px;
      box-sizing: border-box;
      padding-top: 40px;

      .like {
        position: absolute;
        right: 15px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
      }

      .loupe {
        position: absolute;
        left: 15px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
      }

      .icon-like {
        color: #aaa;
      }

      .icon-like_fill {
        color: #000;
      }
    }

    .retxt {
      width: 100%;
      text-align: center;

      h5 {
        margin: 0;
      }

      p {
        font-size: 14px;
        margin: 0;
      }

      p:nth-of-type(1) {
        font-size: 16px;
      }
    }
  }
}
</style>
